/**
 * @typedef {String} Category
 */
export const CATEGORY_ID = {
  MAFIOSO: 'CATEGORY_ID.MAFIOSO',
  UNCATEGORIZED: 'CATEGORY_ID.UNCATEGORIZED',
  ASCENSION_INFO: 'CATEGORY_ID.ASCENSION_INFO',
  SNAPSHOT_INFO: 'CATEGORY_ID.SNAPSHOT_INFO',
  IOTM: 'CATEGORY_ID.IOTM',
  PATH: 'CATEGORY_ID.PATH',
  QUEST: 'CATEGORY_ID.QUEST',
  COMBAT: 'CATEGORY_ID.COMBAT',
  NONCOMBAT: 'CATEGORY_ID.NONCOMBAT',
  OPTIONS: 'CATEGORY_ID.OPTIONS',
  DIET: 'CATEGORY_ID.DIET',
  USE_ITEM: 'CATEGORY_ID.USE_ITEM',
  EFFECTS: 'CATEGORY_ID.EFFECTS',
  CRAFTING: 'CATEGORY_ID.CRAFTING',
  PULLS: 'CATEGORY_ID.PULLS',
  FAMILIARS: 'CATEGORY_ID.FAMILIARS',
  EQUIPMENT: 'CATEGORY_ID.EQUIPMENT',
  VISIT: 'CATEGORY_ID.VISIT',
  TRANSACTIONS: 'CATEGORY_ID.TRANSACTIONS',
  OTHER: 'CATEGORY_ID.OTHER',
}
