/**
 * @typedef {String} EntityType
 */
export const ENTITY_TYPE = {
  OTHER: 'ENTITY_TYPE.OTHER',
  COMBAT_ITEM: 'ENTITY_TYPE.COMBAT_ITEM',
  EQUIPMENT: 'ENTITY_TYPE.EQUIPMENT',
  FAMILIAR: 'ENTITY_TYPE.FAMILIAR',
  COMBAT_SKILL: 'ENTITY_TYPE.COMBAT_SKILL',
  WORKSHED: 'ENTITY_TYPE.WORKSHED',
}

export default ENTITY_TYPE;