/**
 * @typedef {String} DifficultyName
 * @typedef {String} DifficultyAbbr
 */
export const DIFFICULTY_MAP = {
  Hardcore: 'HC',
  Softcore: 'SC',
  Normal: 'SC', // ???
  Casual: 'CAS',
};
/**
 * @typedef {String} PathName
 * @typedef {String} PathAbbr
 */
export const PATH_MAP = {
  'No-Path': 'NP',
  'Standard': 'STD',
  'You, Robot': 'UROBOT',
  'Grey Goo': 'GOO',
  'Low Key Summer': 'LKS',
  'Path of the Plumber': 'PLUMB',
  'Kingdom of Exploathing': 'EXPLO',
  'Two Crazy Random Summer': '2CRS',
  'Dark Gyffte': 'DG',
  'Disguises Delimit': 'DD',
  'G-Lover': 'GLOV',
  'Pocket Familiars': 'POKEFAM',
  'Live. Ascend. Repeat.': 'LAR',
  'Gelatinous Noob': 'GN',
  'Nuclear Autumn': 'NA',
  'The Source': 'SRC',
  'Avatar of West of Loathing': 'AOWOL',
  'Community Service': 'CS',
  'One Crazy Random Summer': '1CRS',
  'Actually Ed the Undying': 'ED',
  'Picky': 'PICKY',
  'Heavy Rains': 'HR',
  'Slow and Steady': 'S&S',
  'Avatar of Sneaky Pete': 'PETE',
  'Class Act II: A Class For Pigs': 'CAII',
  'KOLHS': 'KOLHS',
  'BIG!': 'BIG',
  'Avatar of Jarlsberg': 'JARLS',
  'Class Act': 'CA',
  'Zombie Slayer': 'ZS',
  'Bugbear Invasion': 'BI',
  'Avatar of Boris': 'BORIS',
  'Trendy': 'TREND',
  'Way of the Surprising Fist': 'FIST',
  'Bees Hate You': 'BEES',
  'Bad Moon': 'BM',
  'Oxygenarian': 'OXY',
  'Boozetafarian': 'BOOZY',
  'Teetotaler': 'TEET',
};
